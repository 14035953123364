
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ViewMixin from "@/mixins/Crud/ViewMixin";
import AppConfig from "@/config/AppConfig";
import { mixins } from "vue-class-component";
import { SslCertStoreMixin } from "@/mixins/Stores/SslCertStoreMixin";

const SslCertModule = namespace(AppConfig.SSL_CERTIFICATE_STORE);

@Component({
  components: {},
  mixins: [ViewMixin],
})
export default class SslCertView extends mixins(SslCertStoreMixin) {
  private name = "SslCertView";

  protected resource = AppConfig.SSL_CERTIFICATE_RESOURCE;
  protected descriptionField = "domain_id";
  protected redirectRouteNameOnDeleteSuccess = "SslCertificateList";

  @SslCertModule.Action("del")
  protected removeAction: any;

  protected onAdd(): void {
    // there is no manual add
  }
}
