var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('h1',{staticClass:"title has-text-primary-dark"},[_vm._v(" "+_vm._s(String(_vm.$t("modules.ssl_certs.view_title")))+" ")])]),_c('div',{staticClass:"column has-text-right"},[_c('div',{staticClass:"buttons is-right"},[(
            (_vm.getSslCertItem && _vm.getSslCertItem.domain_id) ||
            (_vm.getSslCertList &&
              !Array.isArray(_vm.getSslCertList) &&
              _vm.getSslCertList.domain_id)
          )?_c('router-link',{attrs:{"to":{
            name: 'DomainUpdate',
            params: {
              id: _vm.getSslCertItem
                ? _vm.getSslCertItem.domain_id
                : _vm.getSslCertList.domain_id,
            },
          }}},[_c('b-button',[_vm._v("Domain")])],1):_vm._e(),(_vm.$route.name === 'SslCertificateUpdate')?_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){_vm.onRemove(
              Number(_vm.$route.params.id),
              _vm.resource,
              _vm.redirectRouteNameOnDeleteSuccess,
              String(_vm.$tc('general.ssl_certs', 1))
            )}}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)])]),_c('hr'),_c('router-view',{attrs:{"redirectRouteNameOnDeleteSuccess":_vm.redirectRouteNameOnDeleteSuccess},on:{"remove":_vm.onRemove}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }